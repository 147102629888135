import React, { useEffect, useState } from "react";
import { Container, Box, Typography, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import LOGO from "./../assets/images/footer-logo.png";
import axios from "axios";

const PixPayment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.params;

    const [copied, setCopied] = useState(false);
    const [isChecking, setIsChecking] = useState(false); // Controla se a API está sendo consultada

    useEffect(() => {
        if (!data?.response?.id) return;

        const interval = setInterval(() => {
            if (isChecking) return; // Se já estiver checando, não faz outra requisição

            setIsChecking(true); // Marca que está verificando

            axios.get(`https://api.hest.com.br/api/payments/pix/status?orderId=${data.response.id}`)
                .then(response => {
                    if (response.data === 'paid') {
                        clearInterval(interval); // Para o intervalo imediatamente
                        navigate('/success', { state: { successPayment: true } });
                    }
                })
                .catch(error => console.error("Erro ao verificar pagamento:", error))
                .finally(() => setIsChecking(false)); // Libera para a próxima requisição
        }, 10000);

        return () => clearInterval(interval);
    }, [data, navigate, isChecking]); // Dependências atualizadas para evitar chamadas duplicadas

    const handleCopy = () => {
        navigator.clipboard.writeText(data?.pix_code || "");
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    if (!data) {
        return (
            <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
                <Typography variant="h6" color="error">
                    Erro: Nenhum dado recebido.
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    borderRadius: 3,
                    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                    padding: 4,
                }}
            >
                <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
                    Pagamento via PIX
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 3 }}>
                    <Typography variant="body1" sx={{ wordBreak: "break-all", maxWidth: "80%" }}>
                        {data.pix_code}
                    </Typography>
                    <Tooltip title={copied ? "Copiado!" : "Copiar código PIX"}>
                        <IconButton onClick={handleCopy} color="primary" sx={{ ml: 1 }}>
                            {copied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Typography variant="body2" color="textSecondary">
                        Escaneie o QR Code abaixo para efetuar o pagamento:
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        <img
                            src={data.pix_img}
                            alt="QR Code PIX"
                            style={{ width: 200, height: 200, borderRadius: 8 }}
                        />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
                <img src={LOGO} alt="Logo" style={{ width: 150 }} />
            </Box>
        </Container>
    );
};

export default PixPayment;
