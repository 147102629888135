import React from "react";
import { Box } from "@mui/material";
import "./../style.css";

const OrderBump = ({ id, title, image, price, originalPrice, selected, onSelect }) => {
  return (
    <Box className={`order-bump ${selected ? "selected" : ""}`} onClick={() => onSelect(id)}>
      <img src={image} alt={title} />
      <div>
        <div className={selected ? "title-order green" : "title-order"}>{title}</div>
        <div className="product-order">OFERTA VÁLIDA APENAS NESTA PÁGINA!</div>
        <div className="btn-order-value">
          de <s className="price-original">R$ {originalPrice}</s> por apenas <strong className="price-new">R$ {price}</strong>
        </div>
        <button type="button" className="btn-order">{selected ? "Remover" : "Adicionar ao carrinho"}</button>
      </div>
    </Box>
  );
};

export default OrderBump;
