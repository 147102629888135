import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { TickCircle } from "iconsax-react";
import LOGO from "./../assets/images/footer-logo.png";
import { useLocation, useNavigate } from 'react-router-dom';


const Success = () => {

    const location = useLocation();
    const navigate = useNavigate();

    if (!location.state?.successPayment) {
        navigate('/');
        return null;
    }

    return (
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    borderRadius: 3,
                    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <TickCircle size={80} color="#4CAF50" variant="Bold" />

                <Typography variant="h5" fontWeight="bold" color="primary" sx={{ mt: 2 }}>
                    Pagamento Aprovado!
                </Typography>

                <Typography variant="body1" sx={{ mt: 2, color: "textSecondary" }}>
                    Seu pagamento foi confirmado com sucesso. 📩<br />
                    Em breve, você receberá instruções no seu e-mail.
                </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
                <img src={LOGO} alt="Logo" style={{ width: 150 }} />
            </Box>
        </Container>
    );
};

export default Success;
