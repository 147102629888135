import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Checkout from "./pages/Checkout";
import Pix from "./pages/Pix";
import Success from "./pages/Success";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:product" element={<Checkout />} />
        <Route path="/pix" element={<Pix />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </Router>
  );
}