import React, { useEffect, useState } from "react";
import { Container, TextField, FormControlLabel, Radio, RadioGroup, Box, InputAdornment, Select, MenuItem, Alert } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import PIX from "./../assets/images/pix.png";
import LOGO from "./../assets/images/footer-logo.png";
import LOADING from "./../assets/images/loading.gif";
import "./../style.css";
import { BatteryDisable, CardRemove, Check, Timer1 } from "iconsax-react";
import Countdown from "react-countdown";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import VisaIcon from "./../assets/images/visa.png";
import MasterCardIcon from "./../assets/images/mastercard.png";
import EloIcon from "./../assets/images/elo.png";
import AmexIcon from "./../assets/images/amex.png";
import HipercardIcon from "./../assets/images/hipercard.png";
import { Helmet } from "react-helmet";
import OrderBump from "../components/OrderBump";

const orderBumpsData = [
    // {
    //     id: 1,
    //     title: "COMBO DIETA TURBINA SHAPE",
    //     image: "https://media.braip.com/public/cdn/6Qo6sYo704kitRUpIBBq9ZabbXS0vRdNrZIEHJqU.jpg",
    //     originalPrice: "197,00",
    //     price: "19,90",
    // },
    // {
    //     id: 2,
    //     title: "SUPLEMENTO POWER FIT",
    //     image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzgUtmZQtU84qWV8D0NR2MshgXrVTVRR7cA&s",
    //     originalPrice: "150,00",
    //     price: "29,90",
    // },
];

const Checkout = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [cardFlag, setCardFlag] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorPayment, setErrorPayment] = useState(null);
    
    const [selectedBumps, setSelectedBumps] = useState([]);
    const { product } = useParams();

    const handleSelect = (id) => {
        setSelectedBumps((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await axios.get(`https://api.hest.com.br/api/checkout/${product}`).then((response) => {
            setData(response.data);
            setLoading(false);
        });
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            document: "",
            cep: "",
            address: "",
            number: "",
            neighborhood: "",
            city: "",
            state: "",
            complement: "",
            cardNumber: "",
            cardExpiry: "",
            cardCVV: "",
            installments: "1",
            paymentMethod: "credit",
            product: product
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Nome é obrigatório"),
            email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
            phone: Yup.string()
                .required("Telefone obrigatório")
                .min(14, "É necessário informar um telefone válido"),
            document: Yup.string()
            .required("Documento obrigatório")
            .test("cpf-cnpj", "CPF ou CNPJ inválido", (value) => {
                const onlyNumbers = value.replace(/\D/g, "");
                return onlyNumbers.length === 11 ? validateCpf(onlyNumbers) : onlyNumbers.length === 14 ? validateCnpj(onlyNumbers) : false;
            }),
            cep: Yup.string().required("CEP obrigatório")
            .min(9, "É necessário informar um CEP válido"),
            address: Yup.string().required("Endereço obrigatório"),
            number: Yup.string().required("Número obrigatório"),
            neighborhood: Yup.string().required("Bairro obrigatório"),
            city: Yup.string().required("Cidade obrigatória"),
            state: Yup.string().required("Estado obrigatório"),
            complement: Yup.string(),
            cardNumber: Yup.lazy((_, { parent }) =>
                parent.paymentMethod === "credit" ? Yup.string().required("Número do cartão obrigatório").min(19, 'Cartão inválido') : Yup.string()
            ),
            cardExpiry: Yup.lazy((_, { parent }) =>
                parent.paymentMethod === "credit" ? Yup.string().required("Validade obrigatória").min(5, 'Data inválida') : Yup.string()
            ),
            cardCVV: Yup.lazy((_, { parent }) =>
                parent.paymentMethod === "credit" ? Yup.string().required("CVV obrigatório").min(3, 'O CVV deve ter pelo menos 3 dígitos') : Yup.string()
            ),
            installments: Yup.lazy((_, { parent }) =>
                parent.paymentMethod === "credit" ? Yup.string().required("Escolha a quantidade de parcelas") : Yup.string()
            ),
        }),
        onSubmit: (values) => {
            setLoading(true);

            axios.post("https://api.hest.com.br/api/pay", {
                values
            }).then((response) => {

                if(formik.values.paymentMethod === 'pix') {
                    navigate('/pix', { state: { params: response.data } });
                }else{
                    navigate('/success', { state: { successPayment: true } });
                }
                
                setLoading(false);

            }).catch((error) => {
                
                var errorResponse = JSON.parse(error.request.response);
                setErrorPayment(errorResponse.error);
                setLoading(false);
            })
        },
    });

    const formatToBRL = (value) => {
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(value);
    };

    const formatPhoneNumber = (value) => {
        if (!value) return "";

        value = value.replace(/\D/g, ""); // Remove tudo que não for número

        if (value.length === 0) return ""; // Retorna vazio se não houver número
        if (value.length <= 2) return `(${value}`; // Adiciona parênteses apenas se houver ao menos 1 dígito
        if (value.length <= 6) return `(${value.slice(0, 2)}) ${value.slice(2)}`; // (XX) XXXX
        if (value.length <= 10) return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`; // (XX) XXXX-XXXX
        if (value.length > 11) value = value.slice(0, 11); // Limita a 11 números

        return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7)}`; // (XX) XXXXX-XXXX
    };

    const formatCpfCnpj = (value) => {
        if (!value) return "";

        value = value.replace(/\D/g, ""); // Remove tudo que não for número

        if (value.length <= 3) return value;
        if (value.length <= 6) return `${value.slice(0, 3)}.${value.slice(3)}`;
        if (value.length <= 9) return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6)}`;
        if (value.length <= 11) return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}-${value.slice(9)}`; // CPF (XXX.XXX.XXX-XX)

        if (value.length <= 12) return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8)}`; // CNPJ (XX.XXX.XXX/XXXX)
        if (value.length <= 14) return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8, 12)}-${value.slice(12)}`; // CNPJ (XX.XXX.XXX/XXXX-XX)

        return value.slice(0, 14); // Limita a 14 dígitos sem formatar além do necessário
    };

    const searchCep = async (cep) => {
        const cepFormatado = cep.replace(/\D/g, "");

        if (cepFormatado.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cepFormatado}/json/`);
                if (response.data.erro) {
                    formik.setFieldError("cep", "CEP inválido");
                } else {
                    formik.setValues({
                        ...formik.values,
                        address: response.data.logradouro,
                        neighborhood: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf,
                    });
                }
            } catch (error) {
                console.error("Erro ao buscar CEP:", error);
                formik.setFieldError("cep", "Erro ao buscar o CEP");
            }
        }
    };

    const formatCep = (value) => {
        if (!value) return "";
        value = value.replace(/\D/g, "");

        if (value.length > 8) value = value.slice(0, 8);
        if (value.length <= 5) return value;
        return `${value.slice(0, 5)}-${value.slice(5)}`;
    };

    const formatCardNumber = (value) => {
        if (!value) return "";

        value = value.replace(/\D/g, "");
        value = value.slice(0, 16);

        return value
            .replace(/(\d{4})(?=\d)/g, "$1 ")
            .trim();
    };

    const getCardFlag = (number) => {
        const visaRegex = /^4/;
        const masterCardRegex = /^5[1-5]/;
        const eloRegex = /^(636368|438935|504175|451416|509048|509067|509049|509069|509050|509074|509068|509040)/;
        const amexRegex = /^3[47]/;
        const hipercardRegex = /^(606282|3841)/;

        if (visaRegex.test(number)) return VisaIcon;
        if (masterCardRegex.test(number)) return MasterCardIcon;
        if (eloRegex.test(number)) return EloIcon;
        if (amexRegex.test(number)) return AmexIcon;
        if (hipercardRegex.test(number)) return HipercardIcon;

        return null; // Nenhuma bandeira detectada
    };

    const formatCardExpiry = (value) => {
        if (!value) return "";

        value = value.replace(/\D/g, "");
        value = value.slice(0, 4);

        if (value.length >= 3) {
            return `${value.slice(0, 2)}/${value.slice(2)}`;
        }

        return value;
    };

    const validateCpf = (cpf) => {
        cpf = cpf.replace(/\D/g, "");
        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    
        let sum = 0, remainder;
        for (let i = 0; i < 9; i++) sum += parseInt(cpf[i]) * (10 - i);
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf[9])) return false;
    
        sum = 0;
        for (let i = 0; i < 10; i++) sum += parseInt(cpf[i]) * (11 - i);
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        return remainder === parseInt(cpf[10]);
    };
    
    const validateCnpj = (cnpj) => {
        cnpj = cnpj.replace(/\D/g, "");
        if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) return false;
    
        const validateDigit = (position) => {
            let size = position - 7;
            let sum = 0;
            let pos = position;
            for (let i = 0; i < position; i++) {
                sum += parseInt(cnpj[i]) * pos--;
                if (pos < 2) pos = 9;
            }
            let remainder = sum % 11;
            return remainder < 2 ? 0 : 11 - remainder;
        };
    
        return validateDigit(12) === parseInt(cnpj[12]) && validateDigit(13) === parseInt(cnpj[13]);
    };
    

    return (
        <>
            <Helmet>
                <meta property="og:title" content={data.product} />
                <meta property="og:description" content="Super Promoção" />
                <meta property="og:image" content={`https://api.hest.com.br/products/${data.image_default}`} />
                <meta property="og:url" content={`https://pay.hest.com.br/${data.link}`} />
                <meta property="og:type" content="website" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            {
                loading ? <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    width="100vw"
                    overflow="hidden"
                    sx={{ backgroundColor: "#ffca03", position: "fixed", top: 0, left: 0 }}
                >
                    <img src={LOADING} alt="Carregando..." />
                </Box> :
                    <>
                        <Box
                            id="timer"
                            sx={{
                                width: "100%",
                                padding: 3,
                                boxSizing: "border-box",
                                backgroundColor: "#db4133",
                                borderRadius: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 2,
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                <Timer1 color={"#FFF"} size={40} />
                                <Box>
                                    <div id="title" style={{ color: "#FFF", fontWeight: "bold" }}>
                                        Promoção por tempo limitado
                                    </div>
                                    <div id="sub-title" style={{ color: "#FFF" }}>
                                        Valor promocional está prestes a acabar
                                    </div>
                                </Box>
                            </Box>

                            <Box sx={{ color: "#FFF", fontSize: 20, fontWeight: "bold", background: '#e57368', padding: 2, borderRadius: 2, width: 150, textAlign: 'center' }}>
                                <Countdown
                                    date={Date.now() + 9 * 60 * 1000 + 47 * 1000}
                                    key={Date.now()}
                                    renderer={({ minutes, seconds }) => (
                                        <span>
                                            {String(minutes).padStart(2, "0")}:
                                            {String(seconds).padStart(2, "0")}
                                        </span>
                                    )}
                                />
                            </Box>
                        </Box>
                        <Container maxWidth="sm">
                            <form onSubmit={formik.handleSubmit}>
                                <Box display="flex" alignItems="center" gap={2} sx={{ mt: 4 }}>
                                    <Box id="image-product">
                                        <img src={`https://api.hest.com.br/products/${data?.image_default}`} />
                                    </Box>
                                    <Box>
                                        <div id="product-name">{data?.product}</div>
                                        <div id="product-client">Vendedor: {data?.user_name}</div>
                                        <div id="product-value">{data?.installments}x de R$ {formatToBRL(data?.installment_details?.at(-1).monthly_price / 100)}</div>
                                        <div id="product-money">Ou {formatToBRL(data?.price / 100)} à vista</div>
                                    </Box>
                                </Box>
                                {data?.freight > 0 && <Box id="box">
                                    <div className="title-card">Valor do frete: {formatToBRL(data?.freight / 100)}</div>
                                    <div className="subtitle-freight">Este valor será adicionado ao total da compra.</div>
                                </Box>}
                                
                                <Box id="box">
                                    {errorPayment != null && errorPayment != "" &&
                                        <Alert icon={<CardRemove color={'red'} size={25} />} severity="error" style={{marginBottom: 20}}>
                                            {errorPayment}
                                        </Alert>

                                    }
                                    <div className="title-card">Informações pessoais</div>

                                    <div className="input">
                                        <label>Nome completo</label>
                                        <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("name")} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
                                    </div>

                                    <div className="input">
                                        <label>E-mail</label>
                                        <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("email")} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
                                    </div>

                                    <Box display="flex" gap={2}>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>Telefone</label>
                                            <TextField
                                                className="mui-input"
                                                fullWidth
                                                margin="dense"
                                                name="phone"
                                                value={formatPhoneNumber(formik.values.phone)}
                                                onChange={(e) => formik.setFieldValue("phone", formatPhoneNumber(e.target.value))}
                                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                helperText={formik.touched.phone && formik.errors.phone}
                                                inputProps={{ maxLength: 15 }}
                                            />
                                        </div>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>CPF/CNPJ</label>
                                            <TextField
                                                className="mui-input"
                                                inputProps={{ maxLength: 18 }}
                                                fullWidth
                                                margin="dense"
                                                value={formatCpfCnpj(formik.values.document)}
                                                onChange={(e) => formik.setFieldValue("document", formatCpfCnpj(e.target.value))}
                                                error={formik.touched.document && Boolean(formik.errors.document)}
                                                helperText={formik.touched.document && formik.errors.document}
                                            />
                                        </div>
                                    </Box>
                                    <br />
                                    <div className="title-card">Seu endereço</div>
                                    <Box display="flex" gap={2}>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>CEP</label>
                                            <TextField
                                                className="mui-input"
                                                fullWidth
                                                margin="dense"
                                                name="cep"
                                                value={formatCep(formik.values.cep)}
                                                onChange={(e) => {
                                                    formik.setFieldValue("cep", formatCep(e.target.value));
                                                }}
                                                onBlur={() => searchCep(formik.values.cep)}
                                                error={formik.touched.cep && Boolean(formik.errors.cep)}
                                                helperText={formik.touched.cep && formik.errors.cep}
                                                inputProps={{ maxLength: 9 }}
                                            />
                                        </div>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>Endereço</label>
                                            <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("address")} error={formik.touched.address && Boolean(formik.errors.address)} helperText={formik.touched.address && formik.errors.address} />
                                        </div>
                                    </Box>
                                    <Box display="flex" gap={2}>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>Número</label>
                                            <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("number")} error={formik.touched.number && Boolean(formik.errors.number)} helperText={formik.touched.number && formik.errors.number} />
                                        </div>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>Bairro</label>
                                            <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("neighborhood")} error={formik.touched.neighborhood && Boolean(formik.errors.neighborhood)} helperText={formik.touched.neighborhood && formik.errors.neighborhood} />
                                        </div>
                                    </Box>
                                    <Box display="flex" gap={2}>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>Cidade</label>
                                            <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("city")} error={formik.touched.city && Boolean(formik.errors.city)} helperText={formik.touched.city && formik.errors.city} />
                                        </div>
                                        <div className="input" style={{ flex: 1 }}>
                                            <label>Estado</label>
                                            <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("state")} error={formik.touched.state && Boolean(formik.errors.state)} helperText={formik.touched.state && formik.errors.state} />
                                        </div>
                                    </Box>
                                    <div className="input" style={{ flex: 1 }}>
                                        <label>Complemento</label>
                                        <TextField className="mui-input" fullWidth margin="dense" {...formik.getFieldProps("complement")} error={formik.touched.complement && Boolean(formik.errors.complement)} helperText={formik.touched.complement && formik.errors.complement} />
                                    </div>
                                    <br />
                                    <div className="title-card">Meio de pagamento</div>
                                    <div className="subtitle-card">Escolha o método de pagamento de sua preferência</div>
                                    <RadioGroup
                                        value={formik.values.paymentMethod}
                                        onChange={(e) => formik.setFieldValue("paymentMethod", e.target.value)}
                                    >
                                        <FormControlLabel value="credit" control={<Radio />} label={<Box display="flex" alignItems="center"><div className="title-payment">Cartão de crédito</div></Box>} />
                                        {formik.values.paymentMethod === "credit" && (
                                            <>
                                                <div className="input">
                                                    <label>Número do cartão</label>
                                                    <TextField
                                                        fullWidth
                                                        margin="dense"
                                                        value={formik.values.cardNumber}
                                                        error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
                                                        helperText={formik.touched.cardNumber && formik.errors.cardNumber}
                                                        onChange={(e) => {
                                                            const formattedCardNumber = formatCardNumber(e.target.value);
                                                            formik.setFieldValue("cardNumber", formattedCardNumber);
                                                            setCardFlag(getCardFlag(e.target.value.replace(/\D/g, "")));
                                                        }}
                                                        inputProps={{ maxLength: 19 }}
                                                        InputProps={{
                                                            startAdornment: cardFlag && (
                                                                <InputAdornment position="start">
                                                                    <img src={cardFlag} alt="Bandeira do cartão" width={30} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />

                                                </div>
                                                <Box display="flex" gap={2}>
                                                    <div className="input" style={{ flex: 1 }}>
                                                        <label>Validade</label>
                                                        <TextField
                                                            className="mui-input"
                                                            fullWidth
                                                            margin="dense"
                                                            name="cardExpiry"
                                                            value={formatCardExpiry(formik.values.cardExpiry)}
                                                            onChange={(e) => formik.setFieldValue("cardExpiry", formatCardExpiry(e.target.value))}
                                                            error={formik.touched.cardExpiry && Boolean(formik.errors.cardExpiry)}
                                                            helperText={formik.touched.cardExpiry && formik.errors.cardExpiry}
                                                            inputProps={{ maxLength: 5 }} // Permite MM/YY (5 caracteres, contando a "/")
                                                        />
                                                    </div>
                                                    <div className="input" style={{ flex: 1 }}>
                                                        <label>CVV</label>
                                                        <TextField
                                                            className="mui-input"
                                                            fullWidth
                                                            margin="dense"
                                                            name="cardCVV"
                                                            value={formik.values.cardCVV.replace(/\D/g, "").slice(0, 4)}
                                                            onChange={(e) => formik.setFieldValue("cardCVV", e.target.value.replace(/\D/g, "").slice(0, 4))}
                                                            error={formik.touched.cardCVV && Boolean(formik.errors.cardCVV)}
                                                            helperText={formik.touched.cardCVV && formik.errors.cardCVV}
                                                            inputProps={{ maxLength: 4 }}
                                                        />

                                                    </div>
                                                </Box>
                                                <Box style={{ marginTop: 7 }}>
                                                    <div className="input">
                                                        <label>Parcelas</label>
                                                        <Select
                                                            className="mui-input"
                                                            fullWidth
                                                            margin="dense"
                                                            name="installments"
                                                            value={formik.values.installments || data?.installment_details?.at(-1)?.installment}
                                                            onChange={formik.handleChange}
                                                            error={formik.touched.installments && Boolean(formik.errors.installments)}
                                                        >
                                                            {
                                                                data?.installment_details
                                                                    ?.slice()
                                                                    .sort((a, b) => b.installment - a.installment)
                                                                    .map((installment) => (
                                                                        <MenuItem key={installment.installment} value={installment.installment}>
                                                                            {installment.installment}x de R$ {formatToBRL(installment.monthly_price / 100)}
                                                                        </MenuItem>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </div>
                                                </Box>
                                            </>
                                        )}
                                        <FormControlLabel value="pix" control={<Radio />} label={<Box display="flex" alignItems="center"><div className="title-payment">Pix</div><img src={PIX} alt="Pix" width="20" style={{ marginLeft: 10 }} /></Box>} />
                                    </RadioGroup>

                                    <Box id="details-security">
                                        <div id="content">
                                            Seus dados estão seguros conosco. Nós utilizamos criptografia para promover total segurança de ponta a ponta.
                                        </div>
                                        <div id="content-footer">
                                            Prosseguindo você estará de acordo com nossa <a href="">Política de Privacidade</a> e <a href="">Termos de serviço.</a>
                                        </div>
                                    </Box>
                                    {orderBumpsData.length > 0 ?
                                        <>
                                            <div id="title-order">
                                                Oferta exclusiva para você!
                                            </div>
                                            <div id="subtitle-order">
                                                Aproveite esta chance imperdível para garantir produtos incríveis com descontos especiais. Não perca essa oportunidade única!
                                            </div>
                                        </>
                                        : null
                                    }

                                    {orderBumpsData.map((bump) => (
                                        <OrderBump
                                            key={bump.id}
                                            id={bump.id}
                                            title={bump.title}
                                            image={bump.image}
                                            originalPrice={bump.originalPrice}
                                            price={bump.price}
                                            selected={selectedBumps.includes(bump.id)}
                                            onSelect={handleSelect}
                                        />
                                    ))}
                                    {formik.values.paymentMethod === "pix" ? <button id="btn-payment" type="submit">Gerar PIX</button> : <button id="btn-payment" type="submit">Realizar pagamento</button>}

                                </Box>
                                <img src={LOGO} id="logo" />
                            </form>

                        </Container>
                    </>
            }
        </>
    );
};

export default Checkout;